<template>
  <div id="app">
    <AgeCheck v-if="currentStep === 'ageCheck'" />

    <Wrapper v-show="currentStep != 'ageCheck'">
      <Prologue v-show="currentStep === 'prologue'" />
      <ChooseName v-show="currentStep === 'chooseName'" />
      <Fight v-if="currentStep === 'fight'" />
      <Exit v-if="currentStep === 'exit'" />
    </Wrapper>
  </div>
</template>

<script>
import Wrapper from '@/components/Wrapper'
import AgeCheck from '@/components/AgeCheck'
import Prologue from '@/components/Prologue'
import ChooseName from '@/components/ChooseName'
import createSession from '@/mixins/createSession'
import Fight from '@/components/Fight'
import Exit from '@/components/Exit'
import i18n from '@/plugins/i18n'

export default {
  components: { Wrapper, AgeCheck, Prologue, ChooseName, Fight, Exit },
  mixins: [ createSession ],
  mounted () {
    // does this prevent double click default on ios Safari?
    document.addEventListener('dblclick', function(e) {
      e.preventDefault();
    });

    // check for language query
    let lang = new URL(location.href).searchParams.get('lang')
    let country = new URL(location.href).searchParams.get('country')
    let campaignId = new URL(location.href).searchParams.get('campaignId')

    if (lang) {
      i18n.locale = lang
      this.$store.commit('setLanguage', lang)
    }

    if (country) {
      this.$store.commit('setCountry', country)
    }

    // set white glass color if in DACH region (language German)
    if (lang === 'de') {
      this.$store.commit('setGlassColor', 'white')
    }

    // set session
    console.log(campaignId)
    this.getFingerprintAndInitializeSession(campaignId)
  },
}
</script>

<style lang="sass">
</style>
