<template>
  <div class="fight container">
    <Finish
      v-show="finish"
      :winner="winner"
      :looser="looser"
      :finish="finish"
      @resetGame="resetGame"
    />
    <span v-show="!finish">
      <video
        autoplay
        muted
        playsinline
        loop
        class="field-video"
      >
        <source src="@/assets/videos/field.mp4" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>

      <div class="h-full">
        <div ref="countdown" class="countdown">
          <div class="counters counters--top">
            <div class="counter counter--round">{{ $t('fight.round') }} 0{{round}}</div>
            <div class="counter counter--fight">Scrum!</div>
          </div>
          <div class="counters counters--bottom">
            <div class="counter counter--round">{{ $t('fight.round') }} 0{{round}}</div>
            <div class="counter counter--fight">Scrum!</div>
          </div>
        </div>

        <div ref="playerScored">
          <div class="player-scored top">
            {{ roundWinner }}
            <br>
            {{ $t(`fight.wins_round_${round - 1}`) }}
          </div>
          <div class="player-scored">
            {{ roundWinner }}
            <br>
            {{ $t(`fight.wins_round_${round - 1}`) }}
          </div>
        </div>

        <img :src="glassImage" ref="flyingShot" class="shot shot--flying" />
        <img :src="glassImage" ref="shot_top_1" class="shot shot--small shot--rotated shot--idle shot--top-1" />
        <img :src="glassImage" ref="shot_top_2" class="shot shot--small shot--rotated shot--idle shot--top-2" />
        <img :src="glassImage" ref="shot_bottom_1" class="shot shot--small shot--idle shot--bottom-1" />
        <img :src="glassImage" ref="shot_bottom_2" class="shot shot--small shot--idle shot--bottom-2" />

        <div class="player-name top is-text-bold">
          <div class="player-spec">Player One</div>
          <div class="is-text-orange is-uppercase">{{ namePlayerOne }}</div>
        </div>
        <div class="player-name bottom is-text-bold">
          <div class="player-spec">Player Two</div>
          <div class="is-text-orange is-uppercase">{{ namePlayerTwo }}</div>
        </div>


        <div :class="['circle top', { 'animate' : animateCircleTop }]" @animationend="animateCircleTop = false">
          <span class="rotated" >{{ totalClicks === 0 ? 'Tap' : '' }}</span>
        </div>
        <div :class="['circle bottom', { 'animate' : animateCircleBottom }]" @animationend="animateCircleBottom = false">
          <span>{{ totalClicks === 0 ? 'Tap' : '' }}</span>
        </div>

        <div v-if="arenaVisible">
          <div class="tap-zone tap-zone--top" @touchstart="handleTouch('top')" />

          <div class="arena" />

          <div class="player-wrap" :style="{ transform: `translateY(${playerPosition}rem)`}">
            
            <PlayerModel
              position="top"
              :number=n
              v-for="n in 8"
              :key="n"
              :total-clicks="totalClicks"
            />

            <div class="sparks" />

            <PlayerModel
              position="bottom"
              :number=n
              v-for="n in 8"
              :key="n"
              :total-clicks="totalClicks"
            />
   
          </div>

          <div class="tap-zone tap-zone--bottom" @touchstart="handleTouch('bottom')" />
        </div>
      </div>
    </span>
  </div>
</template>

<script>
// globals
const winningDifference = 8
// factor to speed up the movement
const adjustmentFactor = 5

import Vue from 'vue'
import VueAnime from 'vue-animejs'
Vue.use(VueAnime)

import Finish from '@/components/Finish'
import PlayerModel from '@/components/fight/PlayerModel'
import shots from '@/mixins/shots'
import countdown from '@/mixins/countdown'

export default {
  components: { Finish, PlayerModel },
  mixins: [ shots, countdown ],
  data () {
    return {
      scoreTop: 0,
      scoreBottom: 0,
      totalScorePlayer1: 0,
      totalScorePlayer2: 0,
      round: 1,
      winner: '',
      looser: '',
      finish: false,
      totalClicks: 0,
      countdownRunning: false,
      arenaVisible: false,
      animateCircleTop: false,
      animateCircleBottom: false,
      roundWinner: ''
    }
  },
  computed: {
    glassImage () {
      return require(`@/assets/images/ice_cold_shot.png`)
    },
    playerPosition (){
      const position = -1 * (this.scoreBottom - this.scoreTop) * adjustmentFactor
      return position
    },
    namePlayerOne () {
      let name = this.$store.state.namePlayerOne
      if (name === null || name === '') {
        name = 'Player One'
      }
      return name
    },
    namePlayerTwo () {
      let name = this.$store.state.namePlayerTwo
      if (name === null || name === '') {
        name = 'Player Two'
      }
      return name
    },
  },
  mounted () {
    this.startCountdown()
    this.stopBackgroundSound()
    this.playCrowdSound()
    this.animateCircleTop = true
    this.animateCircleBottom = true
  },
  methods: {
    stopBackgroundSound () {
      this.backgroundSound.pause()
      this.backgroundSound.currentTime = 0
    },
    playCrowdSound () {
      this.crowdSound.volume = 0.3
      this.crowdSound.loop = true
      this.crowdSound.play()
    },
    handleTouch (player) {
      if (!this.countdownRunning) {
        if (player === 'top') {
          this.scoreTop += 1
          this.animateCircleTop = true
        } else if (player === 'bottom') {
          this.scoreBottom += 1
          this.animateCircleBottom = true
        }
        this.totalClicks += 1
        this.calculateScore()
      }
    },
    calculateScore () {
      let score = Math.abs(this.scoreTop - this.scoreBottom)

      if (this.scoreTop - this.scoreBottom > winningDifference) {
        this.shotForPlayer('top')
        this.totalScorePlayer1 += 1
        this.round += 1
        this.totalClicks = 0
        this.scoreTop = 0
        this.scoreBottom = 0
        this.arenaVisible = false
        this.roundWinner = this.namePlayerOne

        if (this.totalScorePlayer1 >= 2) {
          setTimeout(() => {
            this.finish = true
            this.$store.commit('increaseGamesPlayed')
            this.winner = this.namePlayerOne
            this.looser = this.namePlayerTwo
          }, 3000)
        } else {
          setTimeout(() => {
            this.startCountdown()
          }, 3000)
        }
      }
      
      if (this.scoreBottom - this.scoreTop > winningDifference) {
        this.shotForPlayer('bottom')
        this.totalScorePlayer2 += 1
        this.round += 1
        this.totalClicks = 0
        this.scoreTop = 0
        this.scoreBottom = 0
        this.arenaVisible = false
        this.roundWinner = this.namePlayerTwo
        
        if (this.totalScorePlayer2 >= 2) {
          setTimeout(() => {
            this.finish = true
            this.$store.commit('increaseGamesPlayed')
            this.winner = this.namePlayerTwo
            this.looser = this.namePlayerOne
          }, 3000)
        } else {
          setTimeout(() => {
            this.startCountdown()
          }, 3000)
        }
      }

      this.crush()

      return score
    },

    resetGame () {
      this.arenaVisible = false
      this.round = 1
      this.scoreTop = 0
      this.scoreBottom = 0
      this.lost = ''
      this.finish = false
      this.shotsReceivedPlayer1 = 0
      this.shotsReceivedPlayer2 = 0
      this.startCountdown()
      this.resetShots()
    },

    crush () {
      const tl_sparks = this.$anime.timeline({
        targets: '.sparks',
        duration: 100,
        easing: 'easeInOutExpo',
        direction: 'alternate',
        loop: false
      });

      tl_sparks
      .add({
        backgroundPosition: '0 200px',
        opacity: 1
      })
      .add({
        backgroundPosition: '0 400px',
        opacity: 0
      })
    }
  },
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.fight
  .buttons
    position: absolute
    bottom: 1rem
    left: 1rem
    width: 100%
    z-index: 30

  .sparks
    position: absolute
    background-image: url("~@/assets/images/sparks.png")
    background-size: cover
    background-position: 0 0
    height: 10rem
    width: 20rem
    left: 50%
    transform: translate(-50%, -50%)
    opacity: 0

  .tap-zone
    position: absolute
    text-align: center
    height: 50%
    padding: 8rem 0
    width: 100%
    font-size: 3rem
    color: white
    z-index: 10
    user-select: none

    &--top
      top: 0
      left: 0

    &--bottom
      bottom: 0
      left: 0

    .total-score
      font-size: 1.5rem

  .middle
    position: absolute
    top: 40%

  .mover
    width: 3rem
    height: 3rem
    background: red

  .player-wrap
    position: absolute
    left: 50%
    top: 50%
    transition: 0.25s linear transform

  .h-full
    position: relative
    .circle
      $circleWidth: 12rem
      width: $circleWidth
      height: $circleWidth
      background: transparent
      border-radius: 50%
      border: 2px solid rgba(255, 255, 255, 1)
      position: absolute
      left: calc(50% - #{$circleWidth/2})
      opacity: 0.5
      box-shadow: 0 0 0.8rem 0.8rem rgba(255, 255, 255, 0.5) inset, 0 0 0.8rem 0.8rem rgba(255, 255, 255, 0.5)
      display: flex
      justify-content: center
      align-items: center
      text-transform: uppercase
      font-size: 2rem
      letter-spacing: 0.1rem
      z-index: 40
      background: rgba(0,0,0,0.1)
      pointer-events: none
      color: white
      text-shadow: 0 0 10px black

      &.bottom
        bottom: 13rem
      &.top
        top: 13rem
      &.animate
        animation: pulse-ring .1s cubic-bezier(0.215, 0.61, 0.355, 1)

      .rotated
        transform: rotate(180deg)

  .field-video
    position: absolute
    width: 100%
    top: 50%
    transform: translateY(-50%)
    right: 0

  .countdown
    position: absolute
    width: 100%
    z-index: 20
    text-align: center
    top: 50%
    transform: translateY(-50%)
    font-family: 'Meister-Bold', Helvetica, Arial, sans-serif
    text-shadow: 0px 0px 20px black
    color: #E9E3C7
    font-weight: bold
    letter-spacing: 0.5rem
    text-transform: uppercase

    .counters
      padding-top: 3rem
      line-height: 0.8

    .counters--top
      transform: rotate(180deg)

    .counter
      font-size: 5rem
      opacity: 0
      will-change: transform, opacity

    .counter--fight
      font-size: 8rem


  .player-scored
    position: absolute
    width: 100%
    z-index: 20
    text-align: center
    font-family: 'Meister-Bold', Helvetica, Arial, sans-serif
    text-shadow: 0px 0px 30px black
    color: #E9E3C7
    font-weight: bold
    text-transform: uppercase
    font-size: 4rem
    opacity: 0
    pointer-events: none
    bottom: 12rem

    &.top
      transform: rotate(180deg)
      top: 12rem
      bottom: inherit

  .shot
    position: absolute
    transform: translate(-50%, -50%)
    z-index: 50

    &--small
      height: 8%

    &--rotated
      transform: rotate(180deg) translate(50%, 50%)

    &--idle
      opacity: .5

    &--flying
      height: 30%
      opacity: 0
      z-index: 51

    &--top-1
      top: 4rem
      left: 8rem

    &--top-2
      top: 4rem
      left: 4rem

    &--bottom-1
      bottom: -2rem
      right: 4rem

    &--bottom-2
      bottom: -2rem
      right: 0rem

.player-name
  position: absolute
  color: $color-orange
  font-size: 2rem
  .player-spec
    font-size: 1rem
    color: white
  &.top
    left: 10rem
    right: 1rem
    top: 3rem
    transform: rotate(180deg)
  &.bottom
    bottom: 3rem
    left: 1rem

@keyframes pulse-ring
  0%
    transform: scale(2)
  80%, 100%
    opacity: 0.2
</style>
