<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  mounted () {
    // TODO: All running audio needs to be put into a container and be
    // turned on and off all at once. This is just a hack for now, since it
    // plays the background/crowd audio even if it wasn't running before
    document.addEventListener('visibilitychange', () => {
      if (document.hidden){
        this.backgroundSound.pause()
        this.crowdSound.pause()
      } else {
        this.backgroundSound.play()
        this.crowdSound.play()
      }
    }, false);
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  height: 100vh
  background: black

  @media (min-width: 430px)
    width: 400px
    margin: 0 auto
    position: relative
    // just for illustration
    border: 1px solid white

  @media (min-height: 850px)
    margin-top: 50px
    height: 800px
</style>
