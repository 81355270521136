<template>
  <div class="privacy-container viewport background">
    <div class="close-button">
      <p class="close" @click="closeOverlay()">X</p>
    </div>
    <div>
      <En v-if="country === 'en'"/>
      <De v-else-if="country === 'de'"/>
      <En v-else/>
    </div>
  </div>
</template>

<script>
import En from '@/components/terms/En'
import De from '@/components/terms/De'
export default {
components: { En, De },
computed: {
    termsOverlayOpen () {
      return this.$store.state.termsOverlay
    },
    country () {
      return this.$store.state.country
    }
  },
  methods : {
     closeOverlay () {
      this.$store.commit('setTermsOverlay', false)
    }
  }
}
</script>
<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.privacy-container
  z-index: 300
  width: 100%
  padding: 50px 10px 0 30px

.close-button
  cursor: pointer
  position: absolute
  top: 0
  right: 0
  padding: 5px
  margin-right: 30px
  font-size: 20px

.close
  position: fixed
  padding: 10px
  background-color: $color-cream
  color: $color-orange

.background
  background-image: url("~@/assets/images/bg-age-check.jpg")
  background-size: contain
  background-repeat: no-repeat

</style>