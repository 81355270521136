<template>
  <div class="finish container">
    <video
      muted
      playsinline
      class="freeze-video"
      ref="freezeVideo"
    >
      <source src="@/assets/videos/freeze.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
    <div class="box">
      <img src="@/assets/images/logo_jaegermeister.png" class="logo" />
      <div align="center" class="claim claim--winner">
        <div class="is-text-orange is-uppercase is-text-bold is-size-large">{{ winner }}</div>
        <div class="is-uppercase is-text-bold is-size-xl">WINS!</div>
      </div>
      <div align="center" class="claim claim--looser">
        <div class="is-text-orange is-uppercase is-text-bold is-size-large">{{ looser }}</div>
        <!-- <img :src="winnerImage" class="winning-claim" /> -->
        <div class="is-uppercase is-text-bold is-size-large">is getting the next Ice Cold shots in</div>
        <img src="@/assets/images/drink_responsibly.svg" class="drink-responsibly" />
      </div>

      <div class="shots">
        <div class="move-in-shot left">
          <img :src="glassImage" class="shot" />
        </div>
        <div class="move-in-shot right">
          <img :src="glassImage" class="shot" />
        </div>
      </div>

      <div class="bottom-set">
        <div v-if="gamesPlayed < 2" class="button move-in-from-bottom" @click="goToChooseName()">
          {{ $t('finish.cta') }}
        </div>
        <div class="button move-in-from-bottom" @click="goToEnding()">
          {{ $t('finish.cta_end') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    winner: {
      type: String
    },
    looser: {
      type: String
    },
    finish: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    glassImage () {
      return require(`@/assets/images/ice_cold_shot.png`)
    },
    gamesPlayed () {
      return this.$store.state.gamesPlayed
    },
    winnerImage () {
      const lang = this.$store.state.language
      return require(`@/assets/images/winner_text_${lang}.svg`)
    }
  },
  methods: {
    goToChooseName () {
      this.clickSound.play()
      this.currentStep = 'chooseName'
    },
    goToEnding () {
      this.clickSound.play()
      if (this.gamesPlayed > 1) {
        this.currentStep = 'exit'
      } else {
        this.prologueStep = 'intro'
        this.currentStep = 'prologue'
      }
    }
  },
  watch: {
    finish () {
      if (this.finish) {
        this.freezeSound.play()
        const video = this.$refs.freezeVideo
        video.play()
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/abstracts/variables"
.finish
  position: relative
  .freeze-video
    z-index: 1
    object-fit: fill
    height: 100%
    width: 100%

  .logo
    height: 10rem
    width: auto
    margin: 0 auto
    left: 50%
    position: absolute
    transform: translateX(-50%)

  .claim
    position: absolute
    top: 15rem
    left: 0
    right: 0
    opacity: 0
    padding: 0 1rem
    &--winner
      animation: appear .5s ease-in 1s forwards, disappear .5s ease-in 3s forwards 

    &--looser
      animation: appear .5s ease-in 4s forwards

  .appear


  .winning-claim
    height: 40%
    display: block

  .drink-responsibly
    height: 1rem
    margin-top: 1rem
    display: block

  .shots
    position: absolute
    left: 0
    right: 0
    bottom: 15rem
    height: 30%
    text-align: center

    .move-in-shot
      position: relative
      display: inline-block
      height: 100%
      transform: translateX(100rem)
      margin: -2rem
      &.left
        animation: moveInRight 1.5s ease-in forwards 1.5s
      &.right
        animation: moveInRight 1.5s ease-in forwards 2s
      .shot
        height: 100%

  .move-in-from-bottom
    transform: translateY(70rem)
    animation: moveInFromBottom 2s ease-in forwards 2s

@keyframes moveInRight
  0%
    opacity: 0

  100%
    opacity: 1
    transform: translateX(0rem)

@keyframes moveInFromBottom
  0%
    opacity: 0

  100%
    opacity: 1
    transform: translateY(0rem)

@keyframes appear
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes disappear
  0%
    opacity: 1
  100%
    opacity: 0

</style>
