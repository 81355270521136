import updateSession from '@/mixins/updateSession'
export default {
  mixins: [ updateSession ],
  computed: {
    windowHeight () {
      return Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.documentElement.clientHeight
      )
    },
    currentStep: {
      get () {
        return this.$store.state.currentStep
      },
      set (val) {
        this.$store.commit('setCurrentStep', val)
      }
    },
    swipeSound () {
      return this.$store.state.sounds.swipeSound
    },
    clickSound () {
      return this.$store.state.sounds.clickSound
    },
    countdownSound () {
      return this.$store.state.sounds.countdownSound
    },
    freezeSound () {
      return this.$store.state.sounds.freezeSound
    },
    backgroundSound () {
      return this.$store.state.sounds.backgroundSound
    },
    crowdSound () {
      return this.$store.state.sounds.crowdSound
    },
    wonSound () {
      return this.$store.state.sounds.wonSound
    },
  }
}
