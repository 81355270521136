const client = require('@/client')

export default {
  data () {
    return {
      fingerprint: null,
      userData: null
    }
  },
  computed: {
    sessionIdentifier () {
      return this.$store.state.session
    },
    currentGameId () {
      return process.env.VUE_APP_GAME_ID
    }
  },
  methods: {
    async updateSessionProgress (data) {

      if (!this.currentGameId) {
        console.log("No game id retrieved. Cannot track.")
        return false
      }

      if (!this.sessionIdentifier) {
        console.log("Fatal: No session retrieved. Cannot track.")
        return false
      }

      const res = await client.put(`public/games/${this.currentGameId}/sessions/${this.sessionIdentifier}`, { progress: data })

      if (res) {
        console.log("session progress updated")
      }
    }
  }
}
